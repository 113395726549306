import React from 'react'
import ResetPassword from '../../components/user/auth/ResetPassword'

function ResetPasswordPage() {
    return (
        <div>
            <ResetPassword />
        </div>
    )
}

export default ResetPasswordPage

