import React from 'react'
import Post from '../.../../../components/admin/home/Post'

function PostMangement() {
    return (
        <div className='min-h-screen '>
            <Post />
        </div>
    )
}

export default PostMangement

