import React from 'react'
import Dashboard from '../../components/admin/home/Dashvoard'
import Nav from '../../components/admin/home/Nav'
import Sidebar from '../../components/admin/home/Sidebar'
function Home() {
    return (
        <div className='min-h-screen'>
            <Dashboard />
        </div>
    )
}

export default Home

