import React from 'react'
import SkillsManagement from '../../components/admin/home/SkillsManagement'

function Skills() {
    return (
        <div className='min-h-screen '>
            <SkillsManagement />
        </div>
    )
}

export default Skills

