import React from 'react'
import RecruiterManagement from '../../components/admin/home/RecruiterManagement'

function Recruiter() {
    return (
        <div className='min-h-screen '>
            <RecruiterManagement />
        </div>
    )
}

export default Recruiter

