import React from 'react'
import SignupPage from '../../components/user/auth/Signup'

function Signup() {
    return (
        <div>
            <Signup />
        </div>
    )
}

export default SignupPage

