import React from 'react'
import Otp from '../../components/user/auth/Otp'

function OtpPage() {
    return (
        <div>
            <Otp />
        </div>
    )
}

export default OtpPage

