import React from 'react'
import Dashboard from '../../components/recruiter/home/Dashboard'
import Nav from '../../components/recruiter/home/Nav'
function HomePage() {
    return (
        <div>
            <Nav />
            <Dashboard />
        </div>
    )
}

export default HomePage

