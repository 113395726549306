import React from 'react'
import Landing from '../../components/user/home/Landing'

function LandingPage() {
    return (
        <div>
            <Landing />
        </div>
    )
}

export default LandingPage

