import React from 'react'
import ViewApplicants from '../../components/recruiter/home/ViewApplicants'
import Nav from '../../components/recruiter/home/Nav'
function Applicants() {
    return (
        <div>
            <Nav />
            <ViewApplicants />
        </div >
    )
}

export default Applicants


