import React from 'react'
import Email from '../../components/user/auth/Email'

function EmailPage() {
    return (
        <div>
            <Email />
        </div>
    )
}

export default EmailPage

